import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount("#app");

/* eslint-disable */
console.log(`2022/12/17 Kazuaki & Kurumi
 _______                               ________            __     __  __                __
|   |   |.---.-..-----..-----..--.--. |  |  |  |.-----..--|  |.--|  ||__|.-----..-----.|  |
|       ||  _  ||  _  ||  _  ||  |  | |  |  |  ||  -__||  _  ||  _  ||  ||     ||  _  ||__|
|___|___||___._||   __||   __||___  | |________||_____||_____||_____||__||__|__||___  ||__|
                |__|   |__|   |_____|                                           |_____|`);
/* eslint-enable */
