<template>
  <section class="prepaid">
    <div class="title">
      <h1 class="ja">キャッシュレスご祝儀</h1>
      <a href="#linepay">
        <button class="me">
          <img height="15" :src="require('@/assets/linepay.svg')" />
        </button>
      </a>
      <a href="#paypay">
        <button>
          <img height="15" :src="require('@/assets/paypay.png')" />
        </button>
      </a>
    </div>
    <div class="content" id="linepay">
      <h2><img height="30" :src="require('@/assets/linepay.svg')" /></h2>
      <div>
        LINE Payの場合はトークルームからの個人間送金になります。
        <br />
        <br />
        手順
        <ol>
          <li>新郎新婦のトークルームを開く</li>
          <li>
            プラスボタンを押す
            <br />
            <img height="500" :src="require('@/assets/prepaid/linepay1.jpg')" />
          </li>
          <li>
            送金を選択する
            <br />
            <img height="500" :src="require('@/assets/prepaid/linepay2.jpg')" />
          </li>
          <li>
            送金・送付を選択する
            <br />
            <img height="500" :src="require('@/assets/prepaid/linepay3.jpg')" />
          </li>
          <li>
            金額を入力し、次へを押す
            <br />
            <img height="500" :src="require('@/assets/prepaid/linepay4.jpg')" />
          </li>
          <li>
            メッセージを入力し、送付を押す
            <br />
            <img height="500" :src="require('@/assets/prepaid/linepay5.jpg')" />
          </li>
          <li>
            完了！
            <br />
            <img height="500" :src="require('@/assets/prepaid/linepay6.jpg')" />
          </li>
        </ol>
      </div>
    </div>
    <div class="content" id="paypay">
      <h2><img height="30" :src="require('@/assets/paypay.png')" /></h2>
      手順
      <br />
      <small>
        送金の際は送り元の方のお名前がわかるように
        <br />
        アカウント名もしくはメッセージ欄に
        <br />
        お名前の記載をお願いいたします。
      </small>
      <ol>
        <li>
          下記PayPayのリンクをクリック
          <br />
          <a
            class="break"
            href="https://qr.paypay.ne.jp/p2p01_T2AdICBU80CcXPIU"
          >
            https://qr.paypay.ne.jp/p2p01_T2AdICBU80CcXPIU
          </a>
        </li>
        <li>
          PayPayの送金画面が起動する
          <br />
          <img height="500" :src="require('@/assets/prepaid/paypay1.jpg')" />
        </li>
        <li>
          金額を入力し、次へ
          <br />
          <img height="500" :src="require('@/assets/prepaid/paypay2.jpg')" />
        </li>
        <li>
          メッセージ欄に名前を入力し、送付する
          <br />
          <small>※PayPayマネーライトでも大丈夫です！</small>
          <br />
          <img height="500" :src="require('@/assets/prepaid/paypay3.jpg')" />
        </li>
        <li>
          完了！
          <br />
          <small
            >※友達ではないため送付できない旨が表示された場合は友達追加をお願いします</small
          >
          <br />
          <img height="500" :src="require('@/assets/prepaid/paypay4.jpg')" />
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isReady: false,
    };
  },
  methods: {
    onReady() {
      this.isReady = true;
    },
  },
};
</script>

<style lang="scss">
.prepaid .title {
  margin: auto;
  max-width: 600px;
  width: 80%;
  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #aaa;
}
.prepaid .content {
  margin: auto;
  margin-bottom: 30px;
  max-width: 600px;
  width: 80%;
  padding: 20px;
  border-bottom: 1px solid #aaa;
}
.prepaid .content h1 {
  font-size: 40px;
  font-family: "Great Vibes", cursive;
}
.prepaid .content section {
  margin: 0;
  padding: 30px 0;
}
.prepaid .ja {
  font-family: "Sawarabi Mincho" !important;
}
.prepaid ol {
  margin-left: 20px;
  text-align: left;
}
.prepaid li {
  margin-bottom: 15px;
}
.prepaid .me {
  margin-right: 10px;
}
.prepaid .break {
  word-wrap: break-word;
}
</style>
