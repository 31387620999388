var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('first-view',{attrs:{"images":[
        require('@/assets/f1.jpg'),
        require('@/assets/f2.jpg'),
        require('@/assets/f3.jpg'),
      ]},on:{"ready":_vm.onReady}},[_c('tspan',{attrs:{"fill":"#4f8a5d"}},[_vm._v("●")]),_vm._v(" 2019.8.21 KURUMI to KAZUAKI "),_c('tspan',{attrs:{"fill":"#a2d7dd"}},[_vm._v("●")]),_vm._v(" 2019.8.21 KAZUAKI to KURUMI "),_c('tspan',{attrs:{"fill":"#fffffb","stroke":"#aaa"}},[_vm._v("●")]),_vm._v(" 2022.12.17 K&K to ALL GUESTS ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReady),expression:"isReady"}]},[_c('message',[_c('p',[_vm._v("皆様にはご健勝のこととお慶び申し上げます")]),_c('p',[_vm._v("このたび 私たちは2019年8月21日に入籍し")]),_c('p',[_vm._v("結婚式を挙げることになりました")]),_c('p',[_vm._v("つきましては親しい皆様の末永いお力添えをいただきたく")]),_c('p',[_vm._v("ささやかながらも宴の席をご用意申し上げました")]),_c('p',[_vm._v("おいそがしい中 誠に恐縮ではありますが")]),_c('p',[_vm._v("ご列席くださいますようお願い申し上げます")]),_c('br'),_c('p',[_vm._v("記")]),_c('div',{staticClass:"center"},[_c('table',[_c('tr',[_c('td',[_vm._v("日時")]),_c('td',[_vm._v(" 令和4年 12月 17日 "),_c('p',[_vm._v("受付 14時45分〜15時15分")]),_c('p',[_vm._v("挙式 15時45分")]),_c('p',[_vm._v("披露宴 16時45分")])])]),_c('tr',[_c('td',[_c('br')])]),_c('tr',[_c('td',[_vm._v("場所")]),_c('td',[_vm._v(" ステラ・デル・アンジェロ"),_c('br'),_c('small',[_vm._v("埼玉県さいたま市中央区上落合2-1-25")])])])])])]),_c('profile',{attrs:{"data":[
          {
            name: '浜田 和明',
            name_en: 'Kazuaki Hamada',
            image: require('@/assets/p1.jpg'),
            desc: `誕生日：1990年1月16日
出身地：香川県丸亀市、三豊市
趣味：ゲーム、お酒、プログラミング
好きなボドゲ：ウボンゴ3D
好きなウイスキー：富士山麓、知多

メッセージ：
せっかくなので趣味を活かして、こちらのWeb招待状は手作りしました。ついに結婚式開催です・・・！みんなありがとう！

当日も楽しく、喋って、飲んで、笑って、が出来る式にするので、心待ちにしておけ！！
            `,
          },
          {
            name: '佐藤 胡実',
            name_en: 'Kurumi Sato',
            image: require('@/assets/p2.jpg'),
            desc: `誕生日：1992年1月21日
出身地：山形県酒田市
趣味：お酒、ボドゲ、料理
好きなボドゲ：センチュリーゴーレム
好きなウイスキー：ラフロイグ

メッセージ：
当初の日程（2020.6.6）から2年半も経ってしまいましたが、みんながお祝いしてくれることがとても嬉しいです。心からありがとう！

私達の結婚式は、新しく、面白く、楽しい式にする予定です
美味しいお酒をたくさん用意しますので、ぜひ当日は車の運転の予定はいれないでくださいね！笑`,
          },
          {
            name: 'うい',
            name_en: 'Ui',
            image: require('@/assets/うい.jpg'),
            desc: `2020年5月24日うまれ
おんなのこ
すき：ばさし
きらい：カリカリ`,
          },
          {
            name: 'むぎ',
            name_en: 'Mugi',
            image: require('@/assets/むぎ.jpg'),
            desc: `2021年4月5日うまれ
おとこのこ
すき：すてーき
きらい：おるすばん`,
          },
        ],"annotation":"※ふたりはおるすばんです 泣"}}),_c('div',{attrs:{"id":"onepoint"}}),_c('div',{staticClass:"image-box"},[_c('h1',[_vm._v("One point")]),_c('div',[_c('img',{attrs:{"src":require('@/assets/ring.jpg')}}),_c('img',{attrs:{"src":require('@/assets/ring2.jpg')}})]),_c('br'),_c('br'),_vm._m(0)]),_c('window-image'),_c('div',{attrs:{"id":"access"}}),_c('access',{attrs:{"date":"令和4年 12月 17日","time":`受付 14時45分〜15時15分
挙式 15時45分
披露宴 16時45分`,"logo":require('@/assets/banquet.png'),"name":"ステラ・デル・アンジェロ","address":"埼玉県さいたま市中央区上落合2-1-25","url":"https://www.stelladellangelo.com/","mapSrc":require('@/assets/map.png'),"map-url":"https://www.google.com/maps/search/?api=1&query=%E3%82%B9%E3%83%86%E3%83%A9%E3%83%BB%E3%83%87%E3%83%AB%E3%83%BB%E3%82%A2%E3%83%B3%E3%82%B8%E3%82%A7%E3%83%AD","desc":`JR京浜東北線／宇都宮線／高崎線
「さいたま新都心駅」徒歩6分

JR埼京線
「北与野駅」徒歩1分`}}),_c('div',{staticClass:"image-box-footer"},[_c('div',[_c('img',{attrs:{"src":require('@/assets/みんな.jpg')}}),_c('img',{attrs:{"src":require('@/assets/ういむぎ.jpg')}}),_c('img',{attrs:{"src":require('@/assets/ういむぎ2.jpg')}})]),_c('div',[_vm._v(" 2022/12/17 Kazuaki & Kurumi ")]),_c('div',[_vm._v(" Ui & Mugi ")])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("ご祝儀袋やピン札をご用意いただかなくても済むように")]),_c('p',[_vm._v("キャッシュレス事前決済を導入することにしました")]),_c('br'),_c('p',[_vm._v("LINE PayかPayPayをお選び頂けます")]),_c('p',[_c('a',{attrs:{"href":"/prepaid"}},[_vm._v("送金方法はこちら")])]),_c('br'),_c('p',[_vm._v("なお 受付が混乱することを避けるため")]),_c('p',[_vm._v("当日は現金のみの対応になります")]),_c('br'),_c('p',[_vm._v("ですが！")]),_c('p',[_vm._v("華美なご祝儀袋ではなく")]),_c('p',[_vm._v("ポチ袋や茶封筒をお使いいただいて構いません！")]),_c('p',[_vm._v("もちろんピン札じゃなくてOKです")])])
}]

export { render, staticRenderFns }