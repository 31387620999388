<template>
  <section ref="section">
    <h1>Profile</h1>
    <div class="profile">
      <div class="e_name">{{ data[0].name_en }}</div>
      <img class="image" :src="data[0].image" />
      <div class="name">{{ data[0].name }}</div>
      <div class="description">{{ data[0].desc }}</div>
    </div>
    <div class="profile">
      <div class="e_name">{{ data[1].name_en }}</div>
      <img class="image" :src="data[1].image" />
      <div class="name">{{ data[1].name }}</div>
      <div class="description">{{ data[1].desc }}</div>
    </div>
    <div class="sub">
      <table>
        <tr>
          <td>
            <div class="profile">
              <div class="e_name jump">{{ data[2].name_en }}</div>
              <img class="image" :src="data[2].image" />
              <div class="name">{{ data[2].name }}</div>
              <div class="description">{{ data[2].desc }}</div>
            </div>
          </td>
          <td>
            <div class="profile">
              <div class="e_name jump second">{{ data[3].name_en }}</div>
              <img class="image" :src="data[3].image" />
              <div class="name">{{ data[3].name }}</div>
              <div class="description">{{ data[3].desc }}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <p>{{ annotation }}</p>
    <span v-for="index in stars" :key="index" class="star" />
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    annotation: {
      type: String,
    },
  },
  data() {
    return {
      stars: [...Array(500)].map((_, i) => i),
    };
  },
  mounted() {
    const stars = this.$refs.section.querySelectorAll(".star");
    stars.forEach(star => {
      function updateStar(star) {
        const minSize = 2;
        const maxSize = 6;
        const size = Math.random() * (maxSize - minSize) + minSize;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        star.style.animationDelay = `${Math.random() * 10}s`;
      }
      updateStar(star);
      star.classList.add("start");
      star.addEventListener("animationend", () => {
        star.classList.remove("start");
        updateStar(star);
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            star.classList.add("start");
          });
        });
      });
    });
  },
};
</script>

<style scoped lang="scss">
section {
  background: linear-gradient(0deg, #11aedc, #115d89, #080f1c);
  overflow: hidden;
  position: relative;
  color: #fff;
}
.profile {
  margin-bottom: 30px;
}
.e_name {
  font-size: 20pt;
}
.e_name.jump {
  animation: jump 2s ease infinite;
}
.e_name.jump.second {
  animation: jump 2s ease 1s infinite;
}
.image {
  max-width: 600px;
  height: 20vh;
  object-fit: cover;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
}
.taped {
  animation: taped 2s ease 1;
}
.name {
  font-size: 20pt;
  margin-bottom: 15px;
}
.description {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  white-space: pre-wrap;
  text-align: left;
  line-height: 1.8em;
}
.sub {
  text-align: center;
  table {
    display: inline-table;
  }
  td {
    min-width: 180px;
  }
}
.star {
  position: absolute;
  display: block;
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(#fff, 0.2);
  border-radius: 50%;
  opacity: 0;
}
.star.start {
  animation: twinkle 1.5s 1;
}
@keyframes twinkle {
  0% {
    opacity: 0;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  5% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
  25% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
