<template>
  <section class="window-image"></section>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
    },
    name: {
      type: String,
    },
    address: {
      type: String,
    },
    url: {
      type: String,
    },
    mapSrc: {
      type: String,
    },
    mapUrl: {
      type: String,
    },
    desc: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.window-image {
  width: 100%;
  height: 10vh;
}
.window-image:before {
  background-image: url("~@/assets/banquet_title.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -2;
}
</style>
