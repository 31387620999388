<template>
  <div>
    <section>
      <h1>Access</h1>
      <div class="content center">
        <p>記</p>
        <table>
          <tr>
            <td>日時</td>
            <td>
              {{ date }}
              <p class="desc">{{ time }}</p>
            </td>
          </tr>
          <tr>
            <td>場所</td>
            <td>
              {{ name }}<br />
              <small>{{ address }}</small>
            </td>
          </tr>
          <tr>
            <td><br /></td>
          </tr>
        </table>
      </div>
      <div class="content">
        <img class="logo" :src="logo" />
        <div class="name">{{ name }}</div>
        <div class="address">{{ address }}</div>
        <div class="url">
          <a :href="url" target="_blank">{{ url }}</a>
        </div>
        <div class="desc">{{ desc }}</div>
        <br />
        <img class="map" :src="mapSrc" />
        <a :href="mapUrl" target="_blank">Google Mapで開く</a>
        <div>
          <slot></slot>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
    },
    name: {
      type: String,
    },
    address: {
      type: String,
    },
    url: {
      type: String,
    },
    mapSrc: {
      type: String,
    },
    mapUrl: {
      type: String,
    },
    desc: {
      type: String,
    },
    date: {
      type: String,
    },
    time: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
section {
  background: linear-gradient(#f1dbcc, #ffffff);
}
.content {
  margin: auto;
  margin-bottom: 30px;
  max-width: 600px;
  width: 80%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
}
.desc {
  margin-top: 20px;
  white-space: pre-wrap;
  font-size: 10pt;
}
a {
  color: #2c3e50;
}
.logo {
  max-width: 100%;
}
img.map {
  margin: auto;
  max-width: 800px;
  width: 100%;
  display: block;
}
.center {
  text-align: center;

  table {
    font-size: 12pt;
    display: inline-table;

    td {
      font-size: 12pt;
      padding-left: 5px;
      vertical-align: top;
      text-align: left;
    }
  }
}
</style>
