<template>
  <div class="content">
    <first-view
      :images="[
        require('@/assets/f1.jpg'),
        require('@/assets/f2.jpg'),
        require('@/assets/f3.jpg'),
      ]"
      @ready="onReady"
    >
      <tspan fill="#4f8a5d">●</tspan>
      2019.8.21 KURUMI to KAZUAKI
      <tspan fill="#a2d7dd">●</tspan>
      2019.8.21 KAZUAKI to KURUMI
      <tspan fill="#fffffb" stroke="#aaa">●</tspan>
      2022.12.17 K&K to ALL GUESTS
    </first-view>
    <div v-show="isReady">
      <message>
        <p>皆様にはご健勝のこととお慶び申し上げます</p>
        <p>このたび 私たちは2019年8月21日に入籍し</p>
        <p>結婚式を挙げることになりました</p>
        <p>つきましては親しい皆様の末永いお力添えをいただきたく</p>
        <p>ささやかながらも宴の席をご用意申し上げました</p>
        <p>おいそがしい中 誠に恐縮ではありますが</p>
        <p>ご列席くださいますようお願い申し上げます</p>
        <br />
        <p>記</p>
        <div class="center">
          <table>
            <tr>
              <td>日時</td>
              <td>
                令和4年 12月 17日
                <p>受付 14時45分〜15時15分</p>
                <p>挙式 15時45分</p>
                <p>披露宴 16時45分</p>
              </td>
            </tr>
            <tr>
              <td><br /></td>
            </tr>
            <tr>
              <td>場所</td>
              <td>
                ステラ・デル・アンジェロ<br />
                <small>埼玉県さいたま市中央区上落合2-1-25</small>
              </td>
            </tr>
          </table>
        </div>
      </message>
      <profile
        :data="[
          {
            name: '浜田 和明',
            name_en: 'Kazuaki Hamada',
            image: require('@/assets/p1.jpg'),
            desc: `誕生日：1990年1月16日
出身地：香川県丸亀市、三豊市
趣味：ゲーム、お酒、プログラミング
好きなボドゲ：ウボンゴ3D
好きなウイスキー：富士山麓、知多

メッセージ：
せっかくなので趣味を活かして、こちらのWeb招待状は手作りしました。ついに結婚式開催です・・・！みんなありがとう！

当日も楽しく、喋って、飲んで、笑って、が出来る式にするので、心待ちにしておけ！！
            `,
          },
          {
            name: '佐藤 胡実',
            name_en: 'Kurumi Sato',
            image: require('@/assets/p2.jpg'),
            desc: `誕生日：1992年1月21日
出身地：山形県酒田市
趣味：お酒、ボドゲ、料理
好きなボドゲ：センチュリーゴーレム
好きなウイスキー：ラフロイグ

メッセージ：
当初の日程（2020.6.6）から2年半も経ってしまいましたが、みんながお祝いしてくれることがとても嬉しいです。心からありがとう！

私達の結婚式は、新しく、面白く、楽しい式にする予定です
美味しいお酒をたくさん用意しますので、ぜひ当日は車の運転の予定はいれないでくださいね！笑`,
          },
          {
            name: 'うい',
            name_en: 'Ui',
            image: require('@/assets/うい.jpg'),
            desc: `2020年5月24日うまれ
おんなのこ
すき：ばさし
きらい：カリカリ`,
          },
          {
            name: 'むぎ',
            name_en: 'Mugi',
            image: require('@/assets/むぎ.jpg'),
            desc: `2021年4月5日うまれ
おとこのこ
すき：すてーき
きらい：おるすばん`,
          },
        ]"
        annotation="※ふたりはおるすばんです 泣"
      />
      <div id="onepoint"></div>
      <div class="image-box">
        <h1>One point</h1>
        <div>
          <img :src="require('@/assets/ring.jpg')" />
          <img :src="require('@/assets/ring2.jpg')" />
        </div>
        <br />
        <br />
        <div>
          <p>ご祝儀袋やピン札をご用意いただかなくても済むように</p>
          <p>キャッシュレス事前決済を導入することにしました</p>
          <br />
          <p>LINE PayかPayPayをお選び頂けます</p>
          <p><a href="/prepaid">送金方法はこちら</a></p>
          <br />
          <p>なお 受付が混乱することを避けるため</p>
          <p>当日は現金のみの対応になります</p>
          <br />
          <p>ですが！</p>
          <p>華美なご祝儀袋ではなく</p>
          <p>ポチ袋や茶封筒をお使いいただいて構いません！</p>
          <p>もちろんピン札じゃなくてOKです</p>
        </div>
      </div>
      <window-image />
      <div id="access"></div>
      <access
        date="令和4年 12月 17日"
        :time="
          `受付 14時45分〜15時15分
挙式 15時45分
披露宴 16時45分`
        "
        :logo="require('@/assets/banquet.png')"
        name="ステラ・デル・アンジェロ"
        address="埼玉県さいたま市中央区上落合2-1-25"
        url="https://www.stelladellangelo.com/"
        :mapSrc="require('@/assets/map.png')"
        map-url="https://www.google.com/maps/search/?api=1&query=%E3%82%B9%E3%83%86%E3%83%A9%E3%83%BB%E3%83%87%E3%83%AB%E3%83%BB%E3%82%A2%E3%83%B3%E3%82%B8%E3%82%A7%E3%83%AD"
        :desc="
          `JR京浜東北線／宇都宮線／高崎線
「さいたま新都心駅」徒歩6分

JR埼京線
「北与野駅」徒歩1分`
        "
      />
      <div class="image-box-footer">
        <div>
          <img :src="require('@/assets/みんな.jpg')" />
          <img :src="require('@/assets/ういむぎ.jpg')" />
          <img :src="require('@/assets/ういむぎ2.jpg')" />
        </div>
        <div>
          2022/12/17 Kazuaki & Kurumi
        </div>
        <div>
          Ui & Mugi
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirstView from "@/components/FirstView.vue";
import Message from "@/components/Message.vue";
import Profile from "@/components/Profile.vue";
import Access from "@/components/Access.vue";
import WindowImage from "@/components/WindowImage.vue";

export default {
  components: {
    FirstView,
    Message,
    Profile,
    Access,
    WindowImage,
  },
  data() {
    return {
      isReady: false,
    };
  },
  methods: {
    onReady() {
      this.isReady = true;
    },
  },
};
</script>

<style lang="scss">
.content h1 {
  font-size: 40px;
  font-family: "Great Vibes", cursive;
}
.content section {
  margin: 0;
  padding: 30px 0;
}
.ja {
  font-family: "Sawarabi Mincho" !important;
}
.center {
  text-align: center;

  table {
    font-size: 12pt;
    display: inline-table;

    td {
      font-size: 12pt;
      padding-left: 5px;
      vertical-align: top;
      text-align: left;
    }
  }
}
.image-box-footer {
  background-color: #fff;
  padding: 20px;
  padding-bottom: 40px;
  img {
    width: 200px;
    object-fit: cover;
  }
}
.image-box {
  background-color: #fff;
  padding: 20px;
  padding-bottom: 40px;
  img {
    width: 100px;
    object-fit: cover;
  }
  div img:first-child {
    margin-right: 20px;
  }
  p {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
}
</style>
